
.certificate-content{
    width: 1300px;
    height: 0;
    padding-bottom:816px;
    position: relative;
    margin: 0 auto;
    .certificate-box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: url("../../../assets/images/teacher/certificate-bg.png") no-repeat;
        background-size: 100% 100%;
        padding: 30px 90px;
        box-sizing: border-box;
        .certificate-info{
            display: flex;
            justify-content: flex-start;
            .certificate-info-left{
                width: 50%;
                text-align: justify;
                .certificate-title{
                    margin: 48px 0 40px 36px;
                    height: 98px;
                    .top{
                        font-size: 30pt;
                        font-family: _GB2312;
                        margin-bottom: 20px;
                        letter-spacing: 13px;
                        height: 52px;
                        overflow: hidden;
                        text-align: center;
                    }
                    .bottom{
                        font-size: 16pt;
                        letter-spacing:4px;
                        font-family:'Times New Roman';
                        height: 25px;
                        overflow: hidden;
                        text-align: center;
                    }
                }
                .certificate-wrapper{
                    .mechanism-text{
                        height: 324px;
                        .top{
                            word-break: break-word;
                            font-size: 16pt;
                            letter-spacing: 6px;
                            margin-bottom: 30px;
                            font-weight: 600;
                            line-height: 2;
                            text-indent: 36px;
                            font-family: _GB2312;
                        }
                        .bottom{
                            line-height: 2;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp:5;
                            font-size: 16pt;
                            font-weight: 600;
                            text-indent: 36px;
                            font-family: "Times New Roman";
                        }
                    }
                }
                .evaluate-title{
                    font-size: 12pt;
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    height: 100px;
                    line-height: 2;
                    margin: 14px auto;
                    width:320px;
                    .text{
                        display: flex;
                        font-family: _GB2312;
                        font-size: 12pt;
                    }
                }
                .website{
                    width: 100%;
                    font-family: _GB2312;
                    font-size: 11pt;
                    height: 88px;
                    .bottom{
                        margin: 10px 0;
                    }
                    .left{
                        margin-top: 20px;
                        font-family:'Times New Roman';
                        font-size: 12pt;
                    }
                }
            }
            .certificate-info-right{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 90px;
                box-sizing: border-box;
                .certificate-img{
                    display: flex;
                    align-items: center;
                    height: 262px;
                    .avatar-img{
                        width:110px;
                        height: 153px;
                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .code-img{
                        width: 130px;
                        height: 130px;
                        padding-left: 90px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .certificate-msg{
                    width: 100%;
                    .form-item{
                        display: flex;
                        align-items: center;

                        .form-item-list{

                        }
                        ::v-deep .el-input__inner{
                            border-right: none;
                            border-left: none;
                            border-top: none;
                            height: auto;
                            line-height: 0;
                            border-radius: inherit;
                            background: transparent;
                        }
                        p{
                            min-width: 46px;
                        }
                    }
                    .english-title{
                        margin: 10px 0;
                    }
                }

                .data{
                    position: relative;
                    bottom: 15px;
                    margin: 0 auto;
                    height: 100px;
                    width: 230px;
                    line-height: 2;
                }

            }
            .mechanism-name{
                text-decoration: underline;
            }
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
    .clearFix:after{
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
    }
    .clearFix{
        *zoom: 1;
    }
}
.blank-name {
    width: 80px;
    display: inline-block;
    border-bottom: 2px solid #333;
    margin-right: 8px;
}
.no-certificate-name {
    position: relative;
    top: 50px;
}
