
.main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    justify-content: center;
    box-sizing: border-box;
    .btn-footer {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-left: 20px;
        .btn{
            width: 64px;
            height: 64px;
            background: #fff;
            border: 1px solid #D2D2D2;
            color:#A0A0A0;
            border-radius: 4px;
            margin-left: 10px;
            span{
                display: flex;
                flex-direction: column;
                i{
                    height: 16px;
                    line-height: 0;
                    font-size: 20px;
                    color: #A0A0A0;
                }
            }
        }
        .btn-blue{
            width: 64px;
            height: 64px;
            background: #E4ECFF;
            border: 1px solid #2461EF;
            color: #2461EF;
            border-radius: 4px;
            margin: 30px 10px;
            span{
                display: flex;
                flex-direction: column;
                i{
                    height: 16px;
                    line-height: 0;
                    font-size: 20px;
                }
            }
        }
        .btn-g{
            width: 64px;
            height: 64px;
            background: #E7F6EF;
            border: 1px solid #2DC079;
            color: #2DC079;
            border-radius: 4px;
            span{
                margin-left: -8px;
            }
        }
    }
    .certificate-item{
        display: flex;
        align-items: center;
    }
}
